import { useIntl } from "react-intl";
import { minute } from "../../../utils/utils";
import OptionCard from "../OptionCard";

const Configuration = (props) => {
  const { model } = props;
  const intl = useIntl();
  let prediction_hours = 0;
  let prediction_minutes =
    (model.predictionHorizon * model.samplingFrequency) / minute;
  if (prediction_minutes > 60) {
    prediction_hours = Math.floor(prediction_minutes / 60);
    prediction_minutes = prediction_minutes % 60;
  }

  const cardData = {
    prediction_horizon:
      prediction_hours > 0
        ? prediction_hours + " " + intl.formatMessage({ id: "hours" })
        : "" + prediction_minutes + " " + intl.formatMessage({ id: "minutes" }),
    lookback_period: model.lookbackPeriod,
    autotune_model: model.autotune?.toString(),
  };

  return (
    <OptionCard
      title={intl.formatMessage({ id: "configuration" })}
      data={cardData}
    />
  );
};

export default Configuration;

import { useMemo, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  BasicCard,
  DarkModeContext,
  useTelemetry,
  TenantIdContext,
  getEpochTime,
  BasicLineChart,
  getRequiredDateFormat,
  BasicCustomTooltip,
} from "@datwyler/shared-components";
import useForecastData from "../../../hooks/useForecastData";
import { generateData, getTickValues } from "../../../utils/utils";

const TrainingData = (props: any) => {
  const { form } = props;
  const { colors }: any = useContext(DarkModeContext);
  const { tenantId }: any = useContext(TenantIdContext);
  const { trainingDataLoading, trainingData, setModelData } = useForecastData(
    tenantId,
    null,
    "setup"
  );
  const intl = useIntl();
  const trainingDates = form.watch("trainingDates");
  const selectedDeviceId = form.watch("selectedDeviceId");
  const parameter = form.watch("parameter");

  useEffect(() => {
    if (
      trainingDates?.startDate &&
      trainingDates?.endDate &&
      selectedDeviceId &&
      parameter
    ) {
      const startDate = getEpochTime(trainingDates.startDate.startOf("day"));
      const endDate = getEpochTime(trainingDates.endDate.endOf("day"));
      setModelData({
        trainingStart: startDate,
        trainingEnd: endDate,
        device: { id: selectedDeviceId },
        parameterName: parameter,
      });
    }
  }, [trainingDates, selectedDeviceId, parameter, setModelData]);

  const chartMargin = {
    top: 50,
    right: 50,
    bottom: 50,
    left: 80,
  };

  const yScale = {
    type: "linear",
    min: "auto",
    max: "auto",
    reverse: false,
  };

  const sliceTooltip = ({ slice }) => {
    return <BasicCustomTooltip slice={slice} />;
  };

  const transformedTrainingData = useMemo(
    () =>
      generateData(
        trainingData,
        intl.formatMessage({ id: parameter ? parameter : "NA" })
      ),
    [trainingData, parameter]
  );

  const tickValues = useMemo(
    () => getTickValues([...transformedTrainingData?.data]),
    [transformedTrainingData?.data]
  );

  return (
    <BasicCard sx={{ height: "506px", marginBottom: "24px" }}>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: 0,
              lineHeight: "24px",
            }}
          >
            {intl.formatMessage({ id: "training_data" })}
          </Typography>
        </Box>
        <Box sx={{ height: "458px" }}>
          {trainingDataLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: colors.datwylerTeal,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (transformedTrainingData?.data || []).length ? (
            <Box sx={{ width: "100%", height: "100%" }}>
              <BasicLineChart
                data={[transformedTrainingData]}
                colors={[colors.datwylerTeal]}
                legends={[]}
                curve={"linear"}
                axisRight={null}
                enablePoints={false}
                margin={chartMargin}
                yScale={yScale}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -15,
                  legend: intl.formatMessage({ id: "date" }),
                  legendOffset: 45,
                  legendPosition: "end",
                  tickValues: tickValues,
                  format: (value) =>
                    `${getRequiredDateFormat(value, "DD/MM/YYYY")}`, // using momentjs to format
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: !!parameter
                    ? intl.formatMessage({ id: parameter })
                    : "",
                  legendOffset: -70,
                  legendPosition: "middle",
                  fill: "red",
                }}
                enableSlices={"x"}
                sliceTooltip={sliceTooltip}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 16, color: colors.widgetTableHeader }}
              >
                {intl.formatMessage({ id: "no_data" })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </BasicCard>
  );
};

export default TrainingData;

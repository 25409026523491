import { useState, useCallback, useEffect } from "react";
import { useTelemetry, useAnalytics } from "@datwyler/shared-components";
import { FINISHED } from "../utils/constants";
import { getTasksOptions } from "../utils/utils";

const useForecastData = (tenantId, model, page = "details") => {
  const [tenantID, setTenantID] = useState(tenantId);
  const [modelData, setModelData] = useState(model);
  const [selectedTask, setSelectedTask] = useState();
  const [pageType, setPageType] = useState(page);

  const {
    averageTelemetriesData: trainingData,
    averageTelemetries: fetchTrainingData,
    averageTelemetriesLoading: trainingDataLoading,
  } = useTelemetry();

  const {
    averageTelemetriesData: actualData,
    averageTelemetries: fetchActualData,
    averageTelemetriesLoading: actualDataLoading,
  } = useTelemetry();

  const {
    fetchTasks,
    fetchTasksData,
    fetchTaskResults,
    fetchTaskResultsData: predictedData,
    fetchTaskResultsLoading: predictedDataLoading,
  } = useAnalytics();

  const getTaskList = useCallback(() => {
    fetchTasks({
      variables: {
        tenantId: tenantID,
        page: { number: 0, size: 999999 }, // set a huge page size
        sort: ["updatedOn", "desc"],
        filter: [`modelId:${modelData.id}`, `status:${FINISHED}`],
      },
    });
  }, [fetchTasks, tenantID]);

  const getTrainingData = useCallback(() => {
    const {
      parameterName,
      trainingStart,
      trainingEnd,
      device: { id },
    } = modelData;
    fetchTrainingData({
      variables: {
        tenantId: tenantID,
        deviceId: id,
        startTime: trainingStart,
        endTime: trainingEnd,
        page: { number: 0, size: 999999 }, // set a huge page size
        sort: ["time,asc"],
        name: parameterName,
      },
    });
  }, [fetchTrainingData, modelData, tenantID]);

  const getActualData = useCallback(() => {
    const {
      parameterName,
      trainingEnd,
      device: { id },
      samplingFrequency,
      predictionHorizon,
    } = modelData;
    fetchActualData({
      variables: {
        tenantId: tenantID,
        deviceId: id,
        startTime: trainingEnd,
        endTime: trainingEnd + samplingFrequency * predictionHorizon * 100,
        page: { number: 0, size: 999999 }, // set a huge page size
        sort: ["time,asc"],
        name: parameterName,
      },
    });
  }, [fetchActualData, modelData, tenantID]);

  const getPredictedData = useCallback(() => {
    fetchTaskResults({
      variables: {
        tenantId: tenantId,
        taskId: selectedTask,
      },
    });
  }, [selectedTask, fetchTaskResults]);

  useEffect(() => {
    if (tenantID && modelData && pageType === "details") {
      getTaskList();
    }
  }, [tenantID, modelData]);

  useEffect(() => {
    if (selectedTask && tenantID && modelData && pageType === "details") {
      getTrainingData();
      getActualData();
      getPredictedData();
    } else if (tenantID && modelData && pageType === "setup") {
      getTrainingData();
    }
  }, [selectedTask, tenantID, modelData]);
  return {
    setModelData,
    setTenantID,
    selectedTask,
    setSelectedTask,
    getTrainingData,
    tasks: getTasksOptions(fetchTasksData?.tasks?.tasks || []),
    trainingData: trainingData?.averageTelemetries?.telemetries || [],
    actualData: actualData?.averageTelemetries?.telemetries || [],
    predictedData: predictedData?.taskResults || [],
    actualDataLoading,
    trainingDataLoading,
    predictedDataLoading,
  };
};

export default useForecastData;

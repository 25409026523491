import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { BasicCard, DarkModeContext } from "@datwyler/shared-components";

const Details = (props) => {
  const { model } = props;
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();
  return (
    <BasicCard
      sx={{ height: "193px", marginBottom: "24px", cursor: "default" }}
    >
      <Box
        sx={{ width: "100%", borderBottom: "1px solid " + colors.blueGray200 }}
      >
        <Box>
          <Typography
            sx={{
              color: colors.subTitle,
              fontFamily: "NotoSans-Regular",
              fontWeight: 500,
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
            }}
          >
            {intl.formatMessage({ id: "device_type" })}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "4px", marginBottom: "5px" }}>
          <Typography
            sx={{
              color: colors.fontTitle,
              fontFamily: "NotoSans-Regular",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
            }}
          >
            {model.device?.type || "NA"}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
          borderBottom: "1px solid " + colors.blueGray200,
        }}
      >
        <Box>
          <Typography
            sx={{
              color: colors.subTitle,
              fontFamily: "NotoSans-Regular",
              fontWeight: 500,
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
            }}
          >
            {intl.formatMessage({ id: "device_name" })}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "4px", marginBottom: "5px" }}>
          <Typography
            sx={{
              color: colors.fontTitle,
              fontFamily: "NotoSans-Regular",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
            }}
          >
            {model.device?.name || "NA"}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
          borderBottom: "1px solid " + colors.blueGray200,
        }}
      >
        <Box>
          <Typography
            sx={{
              color: colors.subTitle,
              fontFamily: "NotoSans-Regular",
              fontWeight: 500,
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
            }}
          >
            {intl.formatMessage({ id: "parameter_name" })}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "4px", marginBottom: "5px" }}>
          <Typography
            sx={{
              color: colors.fontTitle,
              fontFamily: "NotoSans-Regular",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
              textTransform: "capitalize",
            }}
          >
            {intl.formatMessage({ id: model.parameterName })}
          </Typography>
        </Box>
      </Box>
    </BasicCard>
  );
};

export default Details;

import {
  getEpochTime,
  getRequiredDateFormat,
} from "@datwyler/shared-components";
import {
  IDLE,
  TRAINING,
  QUEUE,
  PREDICTING,
  FINISHED,
  FAILED,
  ANALYZING,
  REJECTED,
  REVOKED,
} from "./constants";

export const getBackgroundColor = (status: string, colors: any) => {
  switch (status) {
    case IDLE:
      return colors.chipBgOrange;
    case QUEUE:
      return colors.green50;
    case TRAINING:
      return colors.chipBgBlue;
    case PREDICTING:
      return colors.purple50;
    case ANALYZING:
      return colors.purple50;
    case FINISHED:
      return colors.teal50;
    case FAILED:
      return colors.red50;
    default:
      return colors.natural100;
  }
};

export const getLabelColor = (status: string, colors: any) => {
  switch (status) {
    case IDLE:
      return colors.chipFontOrange;
    case TRAINING:
      return colors.chipFontBlue;
    case QUEUE:
      return colors.green600;
    case PREDICTING:
      return colors.purple600;
    case ANALYZING:
      return colors.purple600;
    case FINISHED:
      return colors.teal600;
    case FAILED:
      return colors.red800;
    default:
      return colors.black;
  }
};

export const minute = 60;

export const getSamplingFreqOptions = (intl) => {
  return [
    {
      priority: 0,
      label: intl.formatMessage({ id: "one_min" }),
      value: 1 * minute,
    },
    {
      priority: 1,
      label: intl.formatMessage({ id: "five_min" }),
      value: 5 * minute,
    },
    {
      priority: 2,
      label: intl.formatMessage({ id: "ten_min" }),
      value: 10 * minute,
    },
    {
      priority: 3,
      label: intl.formatMessage({ id: "thirty_min" }),
      value: 30 * minute,
    },
    {
      priority: 4,
      label: intl.formatMessage({ id: "one_hour" }),
      value: 60 * minute,
    },
    {
      priority: 5,
      label: intl.formatMessage({ id: "two_hours" }),
      value: 120 * minute,
    },
  ];
};

export const formatForecastModelPayload = (formValues, tenantID) => {
  const {
    name,
    parameter,
    selectedDeviceId,
    samplingFreq,
    trainingDates,
    lookBackPeriod,
    predictionHorizon,
    autotune,
  } = formValues;
  const payload = {
    name,
    parameterName: parameter,
    tenant: { id: tenantID },
    device: { id: selectedDeviceId },
    samplingFrequency: samplingFreq,
    samplingFrequencyType: "MINUTE",
    samplingType: "AVERAGE",
    trainingStart: getEpochTime(trainingDates.startDate),
    trainingEnd: getEpochTime(trainingDates.endDate),
    predictionHorizon,
    lookbackPeriod: lookBackPeriod,
    autotune: autotune,
  };
  return payload;
};

export const getTasksOptions = (taskList = []) => {
  return taskList.map((task, index) => ({
    priority: index,
    label: getRequiredDateFormat(task.updatedOn),
    value: task.id,
  }));
};

export const isPredictDisabled = (status) =>
  ![FINISHED, REJECTED, REVOKED, FAILED].some(
    (taskStatus) => taskStatus === status
  );
export const isRetrainDisabled = (status) =>
  ![FINISHED, REJECTED, REVOKED, FAILED, null].some(
    (taskStatus) => taskStatus === status
  );

export const generateData = (dataFromDb = [], id) => {
  const result = {
    id: id,
    data: [],
    min: null,
    max: null,
  };
  dataFromDb.map((row) => {
    result.min = Math.min(result.min, row.value);
    result.max = Math.max(result.max, row.value);
    result.data.push({
      x: new Date(row.time * 1000),
      y: row.value,
    });
  });
  return result;
};

export const getTickValues = (chartData) => {
  const values = [];
  if (chartData) {
    const numTicks = 10;
    const max = chartData.length;
    const intervals = Math.floor(max / numTicks);

    if (intervals < 1) {
      chartData.forEach((d) => {
        values.push(d?.x);
      });
    } else {
      for (let i = 0; i < max; i = i + intervals) {
        values.push(chartData[i].x);
      }
    }
  }
  return values;
};

export const ACTIVE = "ACTIVE";
export const DEACTIVATED = "DEACTIVATED";

export const IDLE = "IDLE";
export const QUEUE = "QUEUE";
export const TRAINING = "TRAINING";
export const PREDICTING = "PREDICTING";
export const ANALYZING = "ANALYZING";
export const REVOKED = "REVOKED";
export const REJECTED = "REJECTED";
export const FINISHED = "FINISHED";
export const FAILED = "FAILED";

export const DEFAULT_FILTERS = {
  startDate: null,
  endDate: null,
  statuses: [],
  name: "",
};

export const TASK_STATUS = [
  "IDLE",
  "QUEUE",
  "TRAINING",
  "PREDICTING",
  "ANALYZING",
  "FINISHED",
  "FAILED",
  "REVOKED",
  "REJECTED",
];

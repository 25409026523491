import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import {
  BasicCard,
  DarkModeContext,
  BasicSelectWithCheckMark,
} from "@datwyler/shared-components";

const Configuration = (props: any) => {
  const { form } = props;
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();

  const getOptions = () => {
    const options = [];
    for (let i = 1; i <= 10; i++) {
      options.push({
        priority: i,
        label: i * 100,
        value: i * 100,
      });
    }

    return options;
  };

  const autotuneOptions = [
    {
      priority: 0,
      label: intl.formatMessage({ id: "true" }),
      value: true,
    },
    {
      priority: 1,
      label: intl.formatMessage({ id: "false" }),
      value: false,
    },
  ];

  return (
    <BasicCard sx={{ height: "245px", marginBottom: "24px" }}>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: 0,
              lineHeight: "24px",
            }}
          >
            {intl.formatMessage({ id: "configuration" })}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "24px" }}>
          <BasicSelectWithCheckMark
            menuItems={getOptions()}
            form={form}
            formItemLabel={"predictionHorizon"}
            label={intl.formatMessage({ id: "prediction_horizon" })}
            variant={"standard"}
            menuItemWidth={"100%"}
            SelectDisplayProps={{
              paddingTop: "7px",
              paddingBottom: "5px",
              height: "20px",
              backgroundColor: "unset",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
              fontFamily: "NotoSans-Regular",
              paddingLeft: "0px",
            }}
            inputLabelSx={{
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
              top: "2px",
            }}
            menuPropsSx={{
              maxWidth: "100%",
              marginTop: "8px",
              maxHeight: "325px",
            }}
          />
        </Box>
        <Box sx={{ marginTop: "22px" }}>
          <BasicSelectWithCheckMark
            menuItems={getOptions()}
            form={form}
            formItemLabel={"lookBackPeriod"}
            label={intl.formatMessage({ id: "lookback_period" })}
            variant={"standard"}
            menuItemWidth={"100%"}
            SelectDisplayProps={{
              paddingTop: "2px",
              paddingBottom: "5px",
              height: "20px",
              backgroundColor: "unset",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
              fontFamily: "NotoSans-Regular",
              paddingLeft: "0px",
            }}
            inputLabelSx={{
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
              top: "0px",
            }}
            menuPropsSx={{
              maxWidth: "100%",
              marginTop: "8px",
              maxHeight: "325px",
            }}
          />
        </Box>
        <Box sx={{ marginTop: "22px" }}>
          <BasicSelectWithCheckMark
            menuItems={autotuneOptions}
            form={form}
            formItemLabel={"autotune"}
            label={intl.formatMessage({ id: "autotune_model" })}
            variant={"standard"}
            menuItemWidth={"100%"}
            SelectDisplayProps={{
              paddingTop: "2px",
              paddingBottom: "5px",
              height: "20px",
              backgroundColor: "unset",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
              fontFamily: "NotoSans-Regular",
              paddingLeft: "0px",
            }}
            inputLabelSx={{
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
              top: "0px",
            }}
            menuPropsSx={{
              maxWidth: "100%",
              marginTop: "8px",
              maxHeight: "295px",
            }}
          />
        </Box>
      </Box>
    </BasicCard>
  );
};

export default Configuration;

import { useState, useCallback, useEffect } from "react";
import { useAnalytics } from "@datwyler/shared-components";
import { DEFAULT_FILTERS } from "../utils/constants";

const useForecastModels = (tenantId) => {
  //pagination
  const [tenantID, setTenantID] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
  const {
    fetchForecastModels,
    fetchForecastModelsData,
    isfetchForecastModelsLoading,
  } = useAnalytics();

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId]);

  const getForecastModels = useCallback(() => {
    fetchForecastModels({
      variables: {
        tenantId: tenantID,
        latestTaskStatus: filters.statuses || [],
        startTime: filters.startDate,
        endTime: filters.endDate,
        page: { number: page, size: rowsPerPage },
        sort: [],
        name: filters.name,
      },
    });
  }, [tenantID, filters, page, rowsPerPage, fetchForecastModels]);

  useEffect(() => {
    tenantID && getForecastModels();
    if (filters.statuses.length > 0) {
    }
  }, [getForecastModels, tenantID, filters, page, rowsPerPage]);

  return {
    modelsLoading: isfetchForecastModelsLoading,
    modelsData: fetchForecastModelsData?.forecastModels?.models || [],
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    totalRows:
      fetchForecastModelsData?.forecastModels?.page?.totalElements || 0,
    filters,
    setFilters,
  };
};

export default useForecastModels;

import { useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import {
  DarkModeContext,
  BasicButton,
  useSnackbar,
} from "@datwyler/shared-components";
import DialogComponent from "../Dialog";
import StatusChip from "../StatusChip";
import { isRetrainDisabled, isPredictDisabled } from "../../utils/utils";

const ForecastDetailHeader = (props: any) => {
  const {
    model,
    trainForecastModel,
    predictForecastModel,
    trainForecastModelData,
    predictForecastModelData,
    refetchModel,
    tenantId,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();
  const [trainConfirmDialog, setTrainConfirmDialog] = useState(false);

  useEffect(() => {
    if (trainForecastModelData?.trainForecastModel?.task?.id) {
      refetchModel();
      enqueueSnackbar(intl.formatMessage({ id: "train_model_success_msg" }), {
        variant: "success",
      });
    }
  }, [trainForecastModelData, enqueueSnackbar]);

  useEffect(() => {
    if (predictForecastModelData?.predictForecastModel?.task?.id) {
      enqueueSnackbar(intl.formatMessage({ id: "predict_model_success_msg" }), {
        variant: "success",
      });
    }
  }, [predictForecastModelData, enqueueSnackbar]);

  const handleRetrain = () => {
    trainForecastModel({
      variables: {
        input: {
          tenant: { id: tenantId },
          model: { id: model.id },
        },
      },
    });
    setTrainConfirmDialog(false);
  };

  const handlePredict = () => {
    predictForecastModel({
      variables: {
        input: {
          tenant: { id: tenantId },
          model: { id: model.id },
        },
      },
    });
  };

  return (
    <>
      <Box sx={{ marginTop: "24px", marginBottom: "24px", display: "flex" }}>
        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          <Typography
            sx={{
              color: colors.screenHeaderTitle,
              fontFamily: "NotoSans-SemiBold",
              fontSize: "24px",
              fontWeight: 600,
              letterSpacing: 0,
              lineHeight: "34px",
            }}
          >
            {model.id
              ? model.name
              : intl.formatMessage({ id: "forecast_setup" })}
          </Typography>
        </Box>
        {model.id && (
          <Box sx={{ display: "inline-flex", flexGrow: 1 }}>
            <Grid container sx={{ marginLeft: "20px", alignItems: "center" }}>
              <Grid xs={10}>
                <StatusChip status={model.latestTaskStatus} />
              </Grid>
              <Grid xs={2} sx={{ display: "flex", justifyContent: "right" }}>
                <BasicButton
                  variant="outlined"
                  onClick={() => setTrainConfirmDialog(true)}
                  sx={{
                    border: "1px solid " + colors.blueGray300,
                    marginRight: "16px",
                    height: "44px",
                    width: "88px",
                  }}
                  disabled={isRetrainDisabled(model.latestTaskStatus)}
                >
                  Retrain
                </BasicButton>
                <BasicButton
                  variant="contained"
                  onClick={handlePredict}
                  sx={{ height: "44px", width: "88px" }}
                  disabled={isPredictDisabled(model.latestTaskStatus)}
                >
                  Predict
                </BasicButton>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <DialogComponent
        isOpen={trainConfirmDialog}
        title={`${intl.formatMessage({ id: "retrain_dialog_title" })} ${
          model?.name
        }`}
        bodyTextContent={intl.formatMessage({ id: "retrain_dialog_body" })}
        confirmButtonText={intl.formatMessage({ id: "retrain_dialog_confirm" })}
        handleClose={setTrainConfirmDialog}
        handleConfirm={handleRetrain}
        cancelButtonText={intl.formatMessage({ id: "retrain_dialog_cancel" })}
      />
    </>
  );
};

export default ForecastDetailHeader;

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, CircularProgress } from "@mui/material";
import {
  BasicCard,
  DarkModeContext,
  useTelemetry,
  TenantIdContext,
  BasicLineChart,
  getRequiredDateFormat,
  BasicCustomTooltip,
  BasicSelectWithoutForm,
} from "@datwyler/shared-components";
import useForecastData from "../../../hooks/useForecastData";
import { generateData, getTickValues } from "../../../utils/utils";

export const SelectDisplayProps = {
  paddingTop: "13px",
  paddingBottom: "12px",
  paddingLeft: "16px",
  height: "19px",
  width: "200px",
  maxWidth: "200px",
};

export const inputLabelSx = {
  fontFamily: "NotoSans-Regular",
  fontSize: "14px",
  letterSpacing: "0.01px",
  lineHeight: "24px",
};

export const menuPaperSx = {
  width: "200px",
  maxWidth: "200px",
  textAlign: "center",
};

const chartMargin = {
  top: 50,
  right: 50,
  bottom: 50,
  left: 80,
};

const ForecastedData = (props: any) => {
  const intl = useIntl();
  const { model } = props;
  const { colors }: any = useContext(DarkModeContext);
  const { tenantId }: any = useContext(TenantIdContext);
  const {
    tasks,
    selectedTask,
    setSelectedTask,
    trainingData,
    actualData,
    predictedData,
    actualDataLoading,
    predictedDataLoading,
    trainingDataLoading,
  } = useForecastData(tenantId, model);

  const [yScale, setYScale] = useState({
    type: "linear",
    min: "auto",
    max: "auto",
    reverse: false,
  });

  useEffect(() => {
    if (tasks.length > 0 && !selectedTask) {
      setSelectedTask(tasks[0].value);
    }
  }, [tasks]);

  const transformedTrainingData = useMemo(
    () =>
      generateData(trainingData, intl.formatMessage({ id: "training_data" })),
    [trainingData]
  );

  const transformedActualData = useMemo(
    () => generateData(actualData, intl.formatMessage({ id: "actual_data" })),
    [actualData]
  );

  const transformedPredictedData = useMemo(
    () =>
      generateData(predictedData, intl.formatMessage({ id: "predicted_data" })),
    [predictedData]
  );

  const chartData = [
    transformedTrainingData,
    transformedActualData,
    transformedPredictedData,
  ];

  const tickValues = useMemo(
    () =>
      getTickValues([
        ...transformedTrainingData?.data,
        ...transformedActualData?.data,
        ...transformedPredictedData?.data,
      ]),
    [
      transformedTrainingData?.data,
      transformedActualData?.data,
      transformedPredictedData?.data,
    ]
  );
  const sliceTooltip = ({ slice }) => {
    return <BasicCustomTooltip slice={slice} data={chartData} />;
  };

  const isResultAvailable =
    transformedTrainingData?.data.length +
    transformedActualData?.data.length +
    transformedPredictedData?.data.length;

  return (
    <BasicCard sx={{ height: "506px", marginBottom: "24px" }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: 0,
              lineHeight: "24px",
            }}
          >
            {intl.formatMessage({ id: "forecasted_data" })}
          </Typography>
          <Box>
            <BasicSelectWithoutForm
              menuItems={tasks}
              formItemLabel={intl.formatMessage({
                id: "previous_prediction_select",
              })}
              label={intl.formatMessage({ id: "previous_prediction_select" })}
              SelectDisplayProps={SelectDisplayProps}
              inputLabelSx={inputLabelSx}
              menuPaperSx={menuPaperSx}
              value={selectedTask}
              onChange={(e) => setSelectedTask(e.target.value)}
            />
          </Box>
        </Box>
        <Box sx={{ height: "458px" }}>
          {trainingDataLoading || actualDataLoading || predictedDataLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: colors.datwylerTeal,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : isResultAvailable ? (
            <Box sx={{ width: "100%", height: "100%" }}>
              <BasicLineChart
                data={chartData}
                colors={[
                  colors.datwylerTeal,
                  colors.yellow500,
                  colors.green600,
                ]}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-left",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 54,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 166,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 18,
                    symbolShape: "square",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    itemTextColor: colors.chartAxisFont,
                  },
                ]}
                curve={"linear"}
                axisRight={null}
                enablePoints={false}
                margin={chartMargin}
                yScale={yScale}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -15,
                  legend: intl.formatMessage({ id: "date" }),
                  legendOffset: 30,
                  legendPosition: "end",
                  tickValues: tickValues,
                  format: (value) =>
                    `${getRequiredDateFormat(value, "DD/MM/YY")}`, // using momentjs to format
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: !!model?.parameterName
                    ? intl.formatMessage({ id: model.parameterName })
                    : "",
                  legendOffset: -70,
                  legendPosition: "middle",
                  fill: "red",
                }}
                enableSlices={"x"}
                sliceTooltip={sliceTooltip}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 16, color: colors.widgetTableHeader }}
              >
                {intl.formatMessage({ id: "no_data" })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </BasicCard>
  );
};

export default ForecastedData;

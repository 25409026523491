import { useState, MouseEvent, useContext } from "react";
import { useIntl } from "react-intl";
import { Box, Popover, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

import {
  BasicSelectWithCheckBox,
  BasicButton,
  FilterIcon,
  DarkModeContext,
  CalendarIcon,
  BasicDateRangePickerWithoutForm,
  getEpochTime,
  BasicTextFieldWithoutForm,
} from "@datwyler/shared-components";
import { TASK_STATUS, DEFAULT_FILTERS } from "../../utils/constants";

const filterContainerStyle = {
  display: "inline-flex",
  height: "44px",
  marginLeft: "24px",
};

const Filters = (props) => {
  const { defaultFilters, setFilters } = props;
  const [interimFilters, setInterimFilters] = useState(defaultFilters);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const open = Boolean(anchorEl);

  const statusOptions = TASK_STATUS.map((task, index) => ({
    priority: index,
    label: intl.formatMessage({ id: task.toLocaleLowerCase() }),
    value: task,
  }));

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeDateCreated = (startDate, endDate) => {
    setInterimFilters({
      ...interimFilters,
      startDate: getEpochTime(startDate),
      endDate: getEpochTime(endDate),
    });
  };

  const handleOnChangeName = (value: String) => {
    setInterimFilters({ ...interimFilters, name: value });
  };

  const handleChangeStatus = (statuses: Array<string>) => {
    setInterimFilters({ ...interimFilters, statuses: statuses });
  };

  const applyFilters = () => {
    setFilters(interimFilters);
    handleClose();
  };

  const resetFilters = () => {
    setFilters({ ...DEFAULT_FILTERS });
    setInterimFilters({ ...DEFAULT_FILTERS });
  };

  return (
    <Box>
      <Box sx={{ paddingTop: "16px", display: "flex", alignItems: "center" }}>
        <Box sx={filterContainerStyle}>
          <BasicButton
            variant="outlined"
            onClick={handleClick}
            sx={{ border: "1px solid " + colors.blueGray300 }}
          >
            <FilterIcon fill={colors.iconColor} />
            <Typography
              sx={{
                fontFamily: "NotoSans-Medium",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: 0,
                lineHeight: "20px",
                marginLeft: "10px",
              }}
            >
              {intl.formatMessage({ id: "filters" })}
            </Typography>
          </BasicButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "10px",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
                borderRadius: "8px",
                backgroundColor: colors.selectDropDownBg,
                width: `282px`,
              },
            }}
          >
            <Box sx={{ padding: "16px" }}>
              <Box sx={{ marginTop: "16px" }}>
                <BasicDateRangePickerWithoutForm
                  handleOnChange={handleChangeDateCreated}
                  label={intl.formatMessage({ id: "date_created_between" })}
                  defaultStartDate={interimFilters.startDate}
                  defaultEndDate={interimFilters.endDate}
                  InputProps={{
                    sx: {
                      height: "44px",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        component="div"
                        style={{ paddingLeft: "-14px" }}
                        disablePointerEvents
                      >
                        <CalendarIcon fill={colors.iconColor} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeStatus}
                  values={interimFilters.statuses}
                  label={intl.formatMessage({ id: "status" })}
                  menuItems={statusOptions}
                />
              </Box>
              <Box sx={{ marginTop: "16px" }}>
                <BasicTextFieldWithoutForm
                  label={intl.formatMessage({ id: "model_name" })}
                  type={"text"}
                  formItemLabel={"name"}
                  handleOnChange={handleOnChangeName}
                  variant={"outlined"}
                  size={"small"}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      letterSpacing: "0.01px",
                      top: "3px",
                    },
                  }}
                  InputProps={{
                    sx: {
                      height: "44px",
                    },
                  }}
                  value={interimFilters.name}
                />
              </Box>
              <Box sx={{ marginTop: "16px", textAlign: "right" }}>
                <BasicButton
                  variant={"outlined"}
                  onClick={resetFilters}
                  sx={{
                    padding: "12px 16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "NotoSans-Regular",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    }}
                  >
                    {intl.formatMessage({ id: "reset" })}
                  </Typography>
                </BasicButton>
                <BasicButton
                  variant={"contained"}
                  onClick={applyFilters}
                  sx={{ marginLeft: "20px", padding: "12px 16px" }}
                >
                  {intl.formatMessage({ id: "apply" })}
                </BasicButton>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

export default Filters;

import { useCallback, useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import ForecastSetup from "../components/ForecastSetup";
import ForecastDetail from "../components/ForecastDetail";
import ForecastDetailHeader from "../components/ForecastDetailHeader";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BasicBreadcrumbs,
  useLoadingGif,
  TenantIdContext,
  useAnalytics,
} from "@datwyler/shared-components";
import useForecastModels from "../hooks/useForecastModels";

const ForecastDetails = () => {
  const [model, setModel] = useState<any>({});
  const { tenantId }: any = useContext(TenantIdContext);
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    addForecastModel,
    trainForecastModel,
    predictForecastModel,
    trainForecastModelData,
    predictForecastModelData,
    fetchForecastModelById,
    fetchForecastModelByIdData,
  } = useAnalytics();

  useEffect(() => {
    setModel(location.state.model || {});
  }, [location.state.model]);

  useEffect(() => {
    if (fetchForecastModelByIdData?.forecastModelById) {
      setModel({ ...fetchForecastModelByIdData?.forecastModelById });
    }
  }, [fetchForecastModelByIdData?.forecastModelById]);

  const backToAnalytics = () => {
    const state = { ...location.state };
    delete state.model;
    navigate("/analytics", {
      state: state,
    });
  };

  const refetchModel = useCallback(() => {
    fetchForecastModelById({
      variables: {
        tenantId: tenantId,
        id: model?.id,
      },
    });
  }, [fetchForecastModelById, model?.id]);

  return (
    <Box
      sx={{
        paddingTop: "76px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "24px",
      }}
    >
      <BasicBreadcrumbs
        activePage={model.id ? "ForecastDetails" : "ForecastSetup"}
      />
      <ForecastDetailHeader
        model={model}
        trainForecastModel={trainForecastModel}
        trainForecastModelData={trainForecastModelData}
        predictForecastModel={predictForecastModel}
        predictForecastModelData={predictForecastModelData}
        refetchModel={refetchModel}
        tenantId={tenantId}
      />
      {model?.id ? (
        <ForecastDetail model={model} />
      ) : (
        <ForecastSetup
          backToAnalytics={backToAnalytics}
          addForecastModel={addForecastModel}
          tenantId={tenantId}
        />
      )}
    </Box>
  );
};

export default ForecastDetails;

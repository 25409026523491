import { Route, Routes } from "react-router-dom";
import Analytics from "./containers/Analytics";
import ForecastDetails from "./containers/ForecastDetails";

const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/analytics/forecastDetails" element={<ForecastDetails />} />
      <Route path="/analytics" element={<Analytics />} />
    </Routes>
  );
};

export default Router;

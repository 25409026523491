import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import Filters from "../components/Filters";
import ModelCard from "../components/ModelCard";
import AnalyticTabs from "../components/AnalyticTabs";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BasicBreadcrumbs,
  BasicScreenHeader,
  useLoadingGif,
  DarkModeContext,
  TenantIdContext,
} from "@datwyler/shared-components";
import useForecastModels from "../hooks/useForecastModels";

const Analytics = (props: any) => {
  const intl = useIntl();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const { colors }: any = useContext(DarkModeContext);
  const { tenantId }: any = useContext(TenantIdContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { modelsLoading, modelsData, filters, setFilters } =
    useForecastModels(tenantId);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => setIsLoading(modelsLoading), [modelsLoading]);

  const handleAddModel = () => {
    navigateToDetails({});
  };

  const navigateToDetails = (model: any) => {
    navigate("/analytics/forecastDetails/", {
      state: {
        ...location.state,
        model: model,
      },
    });
  };

  const getLowerComponent = () => {
    return (
      <>
        <Divider sx={{ borderColor: colors.dividerColor }} />
        <Box sx={{ display: "inline-flex" }}>
          <Filters setFilters={setFilters} defaultFilters={filters} />
        </Box>
      </>
    );
  };

  return (
    <Box
      sx={{
        paddingTop: "76px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "24px",
      }}
    >
      <LoadingGif />
      <BasicBreadcrumbs activePage={"Analytics"} />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "menu_analytics" })}
        addButton={{
          label: intl.formatMessage({ id: "add_trend_model" }),
          onClick: handleAddModel,
          width: "144px",
        }}
        LowerComponent={getLowerComponent()}
      />
      <AnalyticTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <Box sx={{ display: "flex", columnGap: "24px", flexWrap: "wrap" }}>
        {modelsData.map((model) => (
          <ModelCard
            key={model.id}
            model={model}
            navigateToDetails={navigateToDetails}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Analytics;

import { useContext } from "react";
import { useIntl } from "react-intl";
import { getBackgroundColor, getLabelColor } from "../../utils/utils";
import { BasicChip, DarkModeContext } from "@datwyler/shared-components";

const StatusChip = (props: any) => {
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();
  const { status } = props;

  return (
    <BasicChip
      label={intl.formatMessage({ id: status?.toLowerCase() || "NA" })}
      backgroundColor={getBackgroundColor(status, colors)}
      labelSx={{ color: getLabelColor(status, colors) }}
    />
  );
};

export default StatusChip;

import { useIntl } from "react-intl";
import { getSamplingFreqOptions } from "../../../utils/utils";
import OptionCard from "../OptionCard";
import { getRequiredDateFormat } from "@datwyler/shared-components";

const ForecastOption = (props) => {
  const { model } = props;
  const intl = useIntl();

  const getSamplingFreqLabel = () => {
    const samplingFreqOptions = getSamplingFreqOptions(intl);
    const option = samplingFreqOptions.find(
      (opt) => opt.value === model.samplingFrequency
    );
    return option?.label || "NA";
  };

  const cardData = {
    sampling_frequency: getSamplingFreqLabel(),
    // value: "model.value",
    training_date:
      getRequiredDateFormat(model.trainingStart, "DD/MM/YY") +
      " - " +
      getRequiredDateFormat(model.trainingEnd, "DD/MM/YY"),
  };

  return (
    <OptionCard
      title={intl.formatMessage({ id: "forecast_option" })}
      data={cardData}
    />
  );
};

export default ForecastOption;
